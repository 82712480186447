@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:wght@100..900&display=swap");

img {
  object-fit: cover;
}

#banner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
  margin-bottom: 1em;
  width: 100%;
}

#searchLogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
  margin-bottom: 3em;
  width: 200px;
}

#skytransLogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
  margin-bottom: 3em;
  width: 100px;
}

.display-linebreak {
  white-space: pre-line;
}
